import React from 'react';
import './App.css';
import Who from './components/who';
import Contact from './components/contact';
import Footer from './components/footer';
import Products from './components/products';
import logo from './img/Heladopolis.png';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      
      <div className='App_container'>
        <Who></Who>
        <Contact></Contact>
        <Products></Products>
      </div>

      <br></br>
      <Footer></Footer>

    </div>//End of App
  );
}

export default App;
