import './products.css';
import React, {useState} from 'react';
//Pictures
//Helados
//import bhn from '../img/products/BHN.png';
//import bn from '../img/products/BN.png';
//import bhp from '../img/products/BHP.png';
//import bhpg from '../img/products/BHPG.png';
import heladosbanner from '../img/products/basehelado2022.png'
//Matriales
import polipapel from '../img/products/polipapel.png'
import bolsas_esq from '../img/products/bolsas_esq.png';
import concentrados from '../img/products/concentrados.png';
import coberturas from '../img/products/coberturas.png';
import moldes from '../img/products/moldes.png';
import congelador from '../img/products/congelador.png';
import carritos from '../img/products/carritos.png';
import basemayte from '../img/products/basemaytelogo.png'
//Conos
import miki from '../img/products/miki.png';
import sabores from '../img/products/cono_sabores.png';
import canasta from '../img/products/canasta.png';
import galletas from '../img/products/galletas.png';
import conos from '../img/products/conos.png';

function Products(){
    const [disBHN, setDisBHN] = useState(true);
    const [disMAT, setDisMAT] = useState(false);
    const [disCON, setDisCON] = useState(false);

    return(
        <div>
            <h1 className='products_title'>Nuestros Productos</h1>
            <button className='b-pink' onClick={function(){
                if(!disBHN){
                    setDisBHN(true);
                    setDisCON(false);
                    setDisMAT(false);
                }
            }}> Helados </button>
            <button className='b-pink' onClick={function(){
                if(!disMAT){
                    setDisBHN(false);
                    setDisCON(false);
                    setDisMAT(true);
                }
            }}> Materiales </button>
            <button className='b-pink' onClick={function(){
                if(!disCON){
                    setDisBHN(false);
                    setDisCON(true);
                    setDisMAT(false);
                }
            }}> Conos </button>
            <br></br>
            <br></br>

            {disBHN? (
                <div className='heladosbanner'>
                    <img src={heladosbanner} alt=""></img>
                </div>
            ): null}

            {disMAT?(
                <div className='products'>
                    <img src={polipapel} alt=""></img>
                    <img src={bolsas_esq} alt=""></img>
                    <img src={concentrados} alt=""></img>
                    <img src={coberturas} alt=""></img>
                    <img src={moldes} alt=""></img>
                    <img src={congelador} alt=""></img>
                    <img src={carritos} alt=""></img>
                    <img src={basemayte} alt=""></img>
                </div>
            ):null}

            {disCON?(
                <div className='products'>
                    <img src={miki} alt=""></img>
                    <img src={sabores} alt=""></img>
                    <img src={canasta} alt=""></img>
                    <img src={galletas} alt=""></img>
                    <img src={conos} alt=""></img>
                </div>
            ):null}

        </div>

    )
}

export default Products;
