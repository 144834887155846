import './contact.css';
import phone_1 from '../img/contact/phone.png';
import mail_1 from '../img/contact/mail.png';
import home_1 from '../img/contact/home.png';
import eigsa_1 from '../img/contact/eigsa_1.JPG'
import eigsa_2 from '../img/contact/eigsa_2.JPG'

function Contact(){
    return(
        <div>
            <h1 className="contact_title">Contacto</h1>

            <div className="contact">

                <a target="_blank" rel="noreferrer" 
                    href="https://www.google.com/maps/place/EIGSA+DE+PUEBLA/@19.0457888,-98.1933462,15z/data=!4m5!3m4!1s0x0:0xc9d30cde81fa08e4!8m2!3d19.0457888!4d-98.1933462">
                    <img srcSet={`${eigsa_1} 320w,  ${eigsa_2}   960w`} 
                    src={eigsa_2} alt="" className='eigsa' />
                </a>

                <table>
                    <tbody>
                        <tr>
                            <td>
                            <div className='row_info'>
                                <img src={phone_1} alt="">
                                </img>
                                <p>
                                22-23-55-74-19 
                                <br></br>
                                <a href="tel:2223557419">
                                    Llámanos 
                                </a> 
                                &nbsp;&nbsp;o&nbsp;&nbsp;<br></br> 
                                <a target="_blank" rel="noreferrer"
                                    href="https://wa.me/2223557419?text=¡Hola! Quisiera información sobre uno de sus productos">
                                    Mándanos WhatsApp
                                </a>
                                </p>
                            </div>

                            <div className='row_info'>
                                <img src={home_1} alt=""></img>
                                <p>
                                    8 Oriente # 406 Col. Centro. Puebla, Pue. <br></br>
                                    A solo 1 calle del teatro principal.<br></br>
                                    <a target="_blank" rel="noreferrer" 
                                        href="https://www.google.com/maps/place/EIGSA+DE+PUEBLA/@19.0457888,-98.1933462,15z/data=!4m5!3m4!1s0x0:0xc9d30cde81fa08e4!8m2!3d19.0457888!4d-98.1933462" >
                                        Encuéntranos en Google Maps
                                    </a>
                                </p>
                            </div>

                            <div className='row_info'>
                                <img src={mail_1} alt=""></img>
                                <p>
                                    contacto@heladopolis.com <br></br>
                                    <a href="mailto:contacto@heladopolis.com" >Escríbenos</a> 
                                </p>
                            </div>
                            
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Contact;
