import './who.css';

function Who(){
    return(
        <div>
            <h1 className='who_title'> ¿Quiénes somos?</h1>
            <div className="who">  
                <p>
                    Heladopolis es una empresa profesional dedicada a la fabricación y distribución de 
                    helados así como de sus productos relacionados. Nos caracterizamos por siempre buscar 
                    la más alta calidad de nuestros productos y atención al cliente. Contamos con más de 
                    20 años de experiencia en el mercado así como con una estructura tecnológica única en 
                    el país para distinguirnos de nuestros competidores. 
                </p>
            </div>
        </div>
    );
}

export default Who;